


























import { Component, Vue, Prop } from 'vue-property-decorator';

import VueAgile from 'client-website-ts-library/components/VueAgile/Agile.vue';
import { CarouselBreakpoint } from '../../app_code/CarouselBreakpoint';

@Component({
  components: {
    VueAgile,
  },
})
export default class Cycler extends Vue {
  @Prop()
  public readonly darken!: boolean;

  @Prop({ required: true })
  private readonly items!: number;

  @Prop()
  private readonly autoAdvance!: boolean;

  @Prop({ default: 0 })
  private readonly initialSlide!: number;

  @Prop({ default: 5000 })
  private readonly autoAdvanceInterval!: number;

  @Prop({ default: 2500 })
  private readonly speed!: number;

  @Prop({ default: true })
  private readonly loop!: boolean;

  @Prop()
  private readonly hideNav!: boolean;

  @Prop()
  private readonly center!: boolean;

  @Prop({ default: [] })
  private readonly responsive!: CarouselBreakpoint[];

  public settings: object = {
    autoplay: this.autoAdvance,
    autoplaySpeed: this.autoAdvanceInterval,
    centerMode: this.center,
    slidesToShow: this.items,
    responsive: this.responsive,
    navButtons: !this.hideNav,
    loop: this.loop,
    initialSlide: this.initialSlide,
  };

  handleChanged(data: any): void {
    this.$emit('update', {
      index: data.currentSlide,
    });
  }

  goToIndex(index: number): void {
    (this.$refs.cycler as any).goTo(index);
  }
}
